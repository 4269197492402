import * as jose from 'jose'

const convertToJWT = async (laravelToken:string) => {
  const [userId, token] = laravelToken.split('|');
  const jwt = await new jose.SignJWT({ sub: userId, token })
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('30d')
    .sign(new TextEncoder().encode('your_jwt_secret_key'));

  return jwt;
};

export default convertToJWT;
