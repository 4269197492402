type Props = {
  className?: string;
};

export const SmallLoading = ({ className }: Props) => {
  return (
    <div
      className={`h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-t-transparent ${className}`}
    ></div>
  );
};
