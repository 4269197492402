import LoginForm from "../components/forms/LoginForm"

 

const Login = () => {
  return (
    <div className="paddings"><LoginForm /></div>
  )
}

export default Login