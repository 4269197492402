import { useMutation } from "@tanstack/react-query"; 
import { UseMutationOptions } from "@tanstack/react-query/build/types/packages/react-query/src/types";
 
import axios, { AxiosError } from "axios";
import { User } from "../../types";
 
export type AuthLoginApiArgs = {
  phone_number: string;
  password: string;
};
export type AuthLoginApiResponse = User

 
let mutationKey = `${process.env.REACT_APP_API_KEY}/login/employee`;

export const useAuthLogin = (
  options?: UseMutationOptions<
    AuthLoginApiResponse,
    AxiosError<{ error: string } | string>,
    AuthLoginApiArgs
  >
) =>
  useMutation<
    AuthLoginApiResponse,
    AxiosError<{ error: string } | string>,
    AuthLoginApiArgs
  >(
    [mutationKey],
    (args: AuthLoginApiArgs) =>
      axios.post(mutationKey, args).then((res) => res.data),
    options
  );
